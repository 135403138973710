.listing-form {
  margin: 0 auto;
  max-width: 800px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.form-group > label {
  margin-bottom: 10px;
}

.form-group > input {
  padding: 5px;
}

.form-action {
  display: flex;
  justify-content: space-between;
} 

.btn-cancel {
  background-color: #d63031;

}
