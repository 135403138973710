.header {
  display: flex;   
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 20%);
  background: #6c5ce7;

}

.brand {
  padding-left: 20px;
  cursor: pointer;
  color: white;
  font-weight: 600;
}

.brand:hover {
  font-weight: bolder;
}

ul.menu {
  list-style-type: none;
  display: flex;
  margin: 0;
}

ul.menu > li {
  padding: 10px;
  cursor: pointer;
  color: white;
}
ul.menu > li:hover {
  font-weight: bold;
}

.nav-link {
  text-decoration: none;
  color: white;
}
